import React from "react"
import { Pagination } from "antd"

const UAPagination = ({
  currentPage,
  pageSize,
  totalItems,
  onPageChange,
  onPageSizeChange,
}) => {
  const handlePageChange = (page, size) => {
    if ((page - 1) * size >= totalItems) {
      onPageChange(Math.ceil(totalItems / size))
    } else {
      onPageChange(page)
    }
  }

  const handlePageSizeChange = (current, newPageSize) => {
    onPageSizeChange(newPageSize)
    onPageChange(1)
  }
  return (
    <div className="pagination-container">
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalItems}
        onChange={handlePageChange}
        onShowSizeChange={handlePageSizeChange}
        showSizeChanger
        pageSizeOptions={["10", "20", "50"]}
        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      />
    </div>
  )
}

export default UAPagination
