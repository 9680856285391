import React, { useState } from "react"
import { Collapse, Button, Modal } from "antd"
import { DownOutlined, CloseCircleOutlined } from "@ant-design/icons"
import ReleaseVideoModal from "./ReleaseVideoModal"
import commonStrings from "../commonStrings"

import playcricle from "../../assets/images/playcricle.png"
import Newicon from "../../assets/images/Newicon.png"
import { formatReleaseDateTime } from "../utilities"

const { Panel } = Collapse

const ReleaseVideo = ({ currentUser, latestReleaseNotes }) => {
  const [isVisible, setIsVisible] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [activePanel, setActivePanel] = useState(null)
  const [selectedVideoUrl, setSelectedVideoUrl] = useState({})

  const handleClick = (videoUrl) => {
    setSelectedVideoUrl(videoUrl)
    setOpenModal(true)
  }

  const updateUserSeenAPI = () => {
    APIClient.put(
      "/release_notes/update_release_note",
      { user_id: currentUser.id },
      (response) => {
        if (response.message === commonStrings.viewReleaseNotesMessage) {
          setIsVisible(false)
        }
      },
    )
  }

  const handleClose = () => {
    updateUserSeenAPI()
  }

  return (
    <Modal
      visible={isVisible}
      closable={false}
      title={(
        <div className="release-note-header custom-modal-header">
          <div
            className="release-title"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            {commonStrings.releaseNotification}
          </div>
          <span
            className="close-button"
            onClick={handleClose}
            style={{ cursor: "pointer", fontSize: "20px" }}
          >
            <CloseCircleOutlined />
          </span>
          <span className="description-release-video">
            {commonStrings.releaseReview}
          </span>
        </div>
      )}
      className="modal-video "
      footer={null}
      width={650}
      bodyStyle={{ maxHeight: "500px", overflowY: "auto" }}
    >
      <Collapse
        className="video-collapsable custom-scrollbar"
        expandIconPosition="end"
        activeKey={activePanel}
        onChange={(key) => setActivePanel(key)}
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} className="collapse-icon" />
        )}
        collapsible="header"
      >
        {latestReleaseNotes.map((release, index) => {
          const { formattedDate, formattedTime } = formatReleaseDateTime(
            release.updated_at,
            release.created_at,
          )
          return (
            <Panel
              className="custom-collapse-wrapper"
              header={(
                <div className="panel-header">
                  <span className="sea-green-bullet" />
                  <div>
                    <span
                      className="release-versions"
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      Version {release.release_version}
                    </span>
                    <br />
                    <span
                      className="date-of-release"
                      style={{ fontSize: "14px", color: "#666" }}
                    >
                      {formattedDate} - {formattedTime}
                    </span>
                    {release.latest_release && (
                      <span>
                        <img
                          style={{ width: "41px", paddingLeft: "4px" }}
                          src={Newicon}
                          alt="New Release icon"
                        />
                      </span>
                    )}
                  </div>
                </div>
              )}
              key={index + 1}
            >
              <div className="panel-content">
                <div>
                  <div className="panel-heading">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: release.description,
                      }}
                    />
                  </div>
                  <div className="button-video">
                    <div className="button-container">
                      <div>
                        <Button
                          className="watch-video-button"
                          onClick={() => handleClick(release)}
                        >
                          <div>
                            <img
                              src={playcricle}
                              alt="Watch video icon"
                              style={{ color: "#ffffff" }}
                            />
                          </div>
                          <span>{commonStrings.watchVideo}</span>
                        </Button>
                      </div>
                    </div>
                    <div className="new-feature">
                      <span className="new-feature-text">
                        {commonStrings.newFeatures}
                      </span>
                    </div>
                  </div>
                  <div
                    className="new-features-description"
                    dangerouslySetInnerHTML={{
                      __html: release.new_features,
                    }}
                  />

                  <span
                    className="thankyou-text"
                    style={{
                      display: "block",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {commonStrings.greeting}
                  </span>
                </div>
              </div>
            </Panel>
          )
        })}
      </Collapse>

      {openModal && (
        <ReleaseVideoModal
          openStatus={openModal}
          setOpenStatus={setOpenModal}
          videoUrl={selectedVideoUrl}
        />
      )}
    </Modal>
  )
}

export default ReleaseVideo
