import React, { useState } from "react"
import {
  Card, Col, Row, Form, Modal, Alert, InputNumber,
} from "antd"
import _ from "underscore"
import FormItemSelectCell from "./FormItemSelectCell"
import commonStrings from "../../commonStrings"
import componentStyles from "../../styles"
import { isArrayNullOrEmpty } from "../../utilities"

function Gradelevel({
  onSubmit,
  form,
  selectedGradeLevels,
  selectedpopulation,
  handleGradeLevelsChange,
  currentSchool,
  matrix1,
}) {
  const [selectedGrades, setSelectedGrades] = useState(
    selectedGradeLevels.map((grade, index) => ({
      grade,
      student_population: selectedpopulation[index] || 0,
    })) || [],
  )

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [removedGrade, setRemovedGrade] = useState(null)

  const [deselectedValue, setDeselectedValue] = useState(null)

  const handleGradeLevelsDeselect = (deselectedValue) => {
    setDeselectedValue(deselectedValue)
    setIsModalVisible(true)
  }

  const bulkUpdatingStudentCountUniversal = (grade, count) => {
    const studentCount = parseInt(count, 10)

    if (_.isNaN(studentCount) || studentCount <= 0) {
      console.error(
        "Invalid count value. Count must be a valid non-negative integer.",
      )
      return
    }

    if (typeof grade !== "string" || grade.trim() === "") {
      console.error("Invalid grade value. Grade must be a non-empty string.")
      return
    }

    APIClient.put(
      "/program_matrix/bulk_update_student_count",
      {
        grade,
        school_id: currentSchool.id,
        count: studentCount,
        selected_grades: _.isEmpty(matrix1) ? [] : matrix1.selected_grades,
      },
      (response) => {
        console.log(response)
      },
    )
  }

  const updateGradeRecords = async () => {
    const response = await APIClient.put(
      "/program_matrix/update_selected_grades",
      {
        grades: selectedGrades,
        // school_id: currentSchool.id,
      },
    )
    if (response) {
      console.log("Success Message:", response)
    }
    const removeResponse = await APIClient.put(
      "/program_matrix/remove_grades",
      {
        grade: deselectedValue,
        school_id: currentSchool.id,
      },
    )

    if (removeResponse) {
      console.log(`Successfully removed grade: ${deselectedValue}`)
    }
  }

  const handleGradeLevelsSelectChange = (selected) => {
    const newSelectedGrades = selected.map((grade) => {
      const existingGrade = selectedGrades.find((g) => g.grade === grade)
      return {
        grade,
        student_population: existingGrade
          ? existingGrade.student_population
          : null,
      }
    })
    const removedGrades = selectedGrades.filter(
      (grade) => !selected.includes(grade.grade),
    )
    if (removedGrades.length > 0) {
      setRemovedGrade(removedGrades[0])
      setIsModalVisible(true)
    } else {
      setSelectedGrades(newSelectedGrades)
      handleGradeLevelsChange(newSelectedGrades)
    }
  }

  const handleOk = () => {
    const updatedGrades = selectedGrades.filter(
      (grade) => grade.grade !== removedGrade.grade,
    )
    setSelectedGrades(updatedGrades)
    handleGradeLevelsChange(updatedGrades)
    setIsModalVisible(false)
    setRemovedGrade(null)
    updateGradeRecords()
  }

  const handleCancel = () => {
    setRemovedGrade(null)
    setIsModalVisible(false)
  }
  const handleInputChange = (grade, value) => {
    const population = value ? parseInt(value, 10) || 0 : 0
    const newGrades = selectedGrades.map((g) => (g.grade === grade ? { ...g, student_population: population } : g))
    setSelectedGrades(newGrades)
    handleGradeLevelsChange(newGrades)
  }

  const customGradeLevels = [
    "3K",
    "PreK",
    "K",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ]

  return (
    <div>
      {(_.isEmpty(selectedGradeLevels)
        || isArrayNullOrEmpty(selectedpopulation)) && (
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className="my-2">
              <Alert
                style={componentStyles.alertStyle}
                message={commonStrings.enterGradeError}
                type="error"
                closable={false}

                // onClose={onClose}
              />
            </div>
          </Col>
        </Row>
      )}

      <Card
        className="card mb-20"
        style={{ width: "100%" }}
        key={isModalVisible}
      >
        <Form layout="vertical" onFinish={onSubmit} form={form}>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12} lg={12}>
              <div className="gradelevelform">
                <label htmlFor="selected_grades" className="selected_grades">
                  {commonStrings.gradeLevelTitle}
                </label>
                <FormItemSelectCell
                  keys={isModalVisible}
                  colDimensions={{ md: 24, lg: 12 }}
                  allowClear={false}
                  name="selected_grades"
                  mode="multiple"
                  defaultValues={selectedGradeLevels}
                  values={customGradeLevels}
                  handleOnChangeSelect={handleGradeLevelsSelectChange}
                  handleOnDeselect={handleGradeLevelsDeselect}
                  className="inputForm"
                  border="1px solid #C6C6C6"
                />
              </div>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12}>
              {!_.isEmpty(selectedGrades) && (
                <>
                  <span className="gradePopulation">
                    {commonStrings.gradePopulation}
                  </span>
                  <div className="main-selectedGrades">
                    {selectedGrades.map((grade, index) => (
                      <div className="selected-grade-levels" key={index}>
                        <label className="labelName">
                          {`Grade ${grade.grade}`}
                        </label>
                        <div className="grade-input">
                          <Form.Item>
                            <InputNumber
                              className="inner-grade-input"
                              name={`grade_input_${grade.grade}`}
                              value={grade.student_population}
                              min={0}
                              onChange={(numericValue) => {
                                bulkUpdatingStudentCountUniversal(
                                  grade.grade,
                                  numericValue,
                                )
                                handleInputChange(grade.grade, numericValue)
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </Col>
            <Modal
              title={(
                <div className="custom-modal-title">
                  {commonStrings.gradeDeselect}
                </div>
              )}
              open={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              okButtonProps={{
                style: {
                  backgroundColor: "#A6192E",
                  borderColor: "#A6192E",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
              cancelButtonProps={{
                style: {
                  borderRadius: "5px",
                },
              }}
            >
              <span>
                {"Removing "}
                <strong>Grade </strong>
                <strong>
                  {removedGrade ? removedGrade.grade : "the selected grade"}
                </strong>
                {" will also remove it from all related activities."}
              </span>
            </Modal>
          </Row>
        </Form>
      </Card>
    </div>
  )
}
export default Gradelevel
