import React from "react"
import Vision from "../../assets/images/Vision.png"
import group from "../../assets/images/group.png"
import commonStrings from "../commonStrings"

const SchoolBranding = ({ currentSchool }) => currentSchool && (
<div className="school-dashboard">
  <div className="vision-statement">
    <div className="header-branding red">
      <span>
        <img src={Vision} alt="vision" />
      </span>
      <span className="title-branding">{commonStrings.schoolVision}</span>
    </div>
    <div className="content-branding custom-scrollbar-branding">
      <span
        dangerouslySetInnerHTML={{
          __html:
                currentSchool.vision_statement || commonStrings.visionStatement,
        }}
      />
    </div>
  </div>

  <div className="goal-for-year">
    <div className="header-branding teal">
      <img src={group} alt="group" />
      <span className="title-branding">{commonStrings.schoolGoal}</span>
    </div>
    <div className="content-branding custom-scrollbar-branding">
      <span
        dangerouslySetInnerHTML={{
          __html: currentSchool.goals || commonStrings.noGoals,
        }}
      />
    </div>
  </div>
</div>
)
export default SchoolBranding
